@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --redepharma-principal: #F0652B;
    --redepharma-secundaria: #8C1137;
    --redepharma-light: #F3F3F3;
    --redepharma-white: #FFFFFF;
    --redepharma-input-color: #D9D9D973;
    --redepharma-dark: #3C3C3C;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
*:focus {
    outline: none;
}

body {
    overflow: hidden;
}

a {
    text-decoration: none;
}
div strong {
    color: var(--redepharma-secundaria);
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 60px;
    text-align: left;
}

section.acp--screen {
    background: linear-gradient(78.27deg, #F0652B 5.82%, #FF872D 95.68%);
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.acp--container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    align-items: flex-end;
    padding: 6rem;
}

.acp--headerTexts {
    width: 700px;
}

.acp--headerTexts h1 {
    color: var(--redepharma-white);
    font-weight: 800;
    font-size: 5.5rem;
    line-height: 95%;
    text-align: end;
}
.acp--headerTexts h2 {
    color: var(--redepharma-secundaria);
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 100%;
    text-align: end;
}

.acp--bodyTexts {
    display: flex;
    flex-direction: column;
}

.acp--bodyTexts p {
    color: var(--redepharma-white);
    font-weight: 300;
    font-size: 2.6rem;
    line-height: 60px;
    text-align: right;
}

.acp--footerBottom {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: center;
}

.acp--footerBottom .btn-redepharma {
    border: 3px solid var(--redepharma-secundaria);
    background-color: transparent;
    padding: 1.4rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s;
}

.acp--footerBottom .btn-redepharma span {
    color: var(--redepharma-secundaria);
    font-weight: 400;
    font-size: 48px;
    transition: color .25s;
}

.acp--footerBottom .btn-redepharma:hover {
    border: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-secundaria);
    cursor: pointer;
}

.acp--footerBottom .btn-redepharma:hover span {
    color: var(--redepharma-white);
}

.acp--imageRe {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 6rem;
}

.redepharma-input {
    padding: 12px 20px;
    width: 100%;
    margin-bottom: 30px;
    border: 0;
    border-bottom: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-input-color);
    font-size: 30px;
    color: var(--redepharma-dark);
    -webkit-transition: .1s;
    transition: .1s;
}

.redepharma-input::placeholder {
    color: var(--redepharma-dark);
    font-size: 30px;
}

.redepharma-input:focus {
    border-bottom: 0;
    border: 3px solid var(--redepharma-secundaria);
}

.p-termo {
    font-size: 1.9rem !important;
    line-height: 40px !important;
}

.cor-vermelho{
    color: red;
}
.cor-verde{
    color: green;
}



input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (max-width: 1640px) {
    .acp--imageRe {
        width: 400px;
    }
    .acp--imageRe img {
        width: 380px;
    }
    .acp--bodyTexts p {
        font-size: 1.6rem;
        line-height: 50px;
    }
    .p-termo {
        font-size: 1.6rem !important;
        line-height: 35px !important;
    }
    .acp--footerBottom {
        gap: 3rem;
    }
    .acp--footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 1440px) {
    .acp--imageRe {
        width: 300px;
    }
    .acp--imageRe img {
        width: 280px;
    }
    .acp--container {
        width: 860px;
        padding: 3rem;
    }
    .acp--headerTexts {
        width: 595px;
    }
    .acp--headerTexts h1 {
        font-size: 4.5rem;
    }
    .acp--headerTexts h2 {
        font-size: 3.0rem;
    }
    .acp--bodyTexts p {
        font-size: 1.3rem;
        line-height: 40px;
    }
    .p-termo {
        font-size: 1.3rem !important;
        line-height: 30px !important;
    }
    .acp--bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 60px;
        text-align: left;
    }
    .acp--footerBottom {
        gap: 2rem;
    }
    .acp--footerBottom .btn-redepharma {
        width: 20rem;
    }
    .acp--footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 1111px) {
    .acp--imageRe {
        display: none;
    }
    .acp--imageRe img {
        display: none;
    }
    .acp--container {
        width: 860px;
        padding: 3rem;
    }
    .acp--headerTexts {
        width: 595px;
    }
    .acp--headerTexts h1 {
        font-size: 4.5rem;
    }
    .acp--headerTexts h2 {
        font-size: 3.0rem;
    }
    .acp--bodyTexts p {
        font-size: 1.3rem;
        line-height: 40px;
    }
    .p-termo {
        font-size: 1.3rem !important;
        line-height: 30px !important;
    }
    .acp--bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 60px;
        text-align: left;
    }
    .acp--footerBottom {
        gap: 2rem;
    }
    .acp--footerBottom .btn-redepharma {
        width: 20rem;
    }
    .acp--footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 800px) {
    .acp--container {
        width: 680px;
        padding: 1rem;
    }
    .acp--headerTexts {
        width: 342px;
    }
    .acp--headerTexts h1 {
        font-size: 2.575rem;
    }
    .acp--headerTexts h2 {
        font-size: 2rem;
    }
    .acp--bodyTexts p {
        font-size: 1.2rem;
        line-height: 40px;
    }
    .p-termo {
        font-size: 1.2rem !important;
        line-height: 25px !important;
    }
    .acp--bodyTexts p strong {
        font-weight: 800;
        font-size: 1.4rem;
        line-height: 60px;
        text-align: left;
    }
    .acp--footerBottom {
        gap: 1rem;
        flex-direction: column;
    }
    .acp--footerBottom .btn-redepharma {
        width: 20rem;
        padding: 1rem;
    }
    .acp--footerBottom .btn-redepharma span {
        font-size: 30px;
    }
}

@media (max-width: 450px) {
    .acp--bodyTexts p {
        font-size: 1.3rem;
        line-height: 32px;
    }
    .p-termo {
        font-size: 1.3rem !important;
        line-height: 32px !important;
    }
    .acp--bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 32px;
        text-align: left;
    }
}