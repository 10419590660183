@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --redepharma-principal: #F0652B;
    --redepharma-secundaria: #8C1137;
    --redepharma-light: #F3F3F3;
    --redepharma-white: #FFFFFF;
    --redepharma-input-color: #D9D9D973;
    --redepharma-dark: #3C3C3C;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    overflow: hidden;
}

a {
    text-decoration: none;
}

section.screen {
    background: linear-gradient(78.27deg, #F0652B 5.82%, #FF872D 95.68%);
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding: 6rem;
}

.headerTexts {
    width: 730px;
}

.headerTexts h1 {
    color: var(--redepharma-white);
    font-weight: 800;
    font-size: 5.5rem;
    line-height: 95%;
    text-align: end;
}
.headerTexts h2 {
    color: var(--redepharma-secundaria);
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 100%;
    text-align: end;
}

.bodyTexts {
    display: flex;
    flex-direction: column;
}

.bodyTexts p {
    color: var(--redepharma-white);
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 60px;
}

.bodyTexts p b {
    color: var(--redepharma-white);
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 60px;
    text-align: left;
}

.bodyTexts p strong {
    color: var(--redepharma-secundaria);
    font-weight: 800;
    font-size: 2rem;
    line-height: 60px;
    text-align: left;
}

.footerBottom {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: center;
}

.footerBottom .btn-redepharma {
    border: 3px solid var(--redepharma-secundaria);
    background-color: transparent;
    padding: 1.4rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s;
}

.footerBottom .btn-redepharma span {
    color: var(--redepharma-secundaria);
    font-weight: 400;
    font-size: 48px;
    transition: color .25s;
}

.footerBottom .btn-redepharma:hover {
    border: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-secundaria);
    cursor: pointer;
}

.footerBottom .btn-redepharma:hover span {
    color: var(--redepharma-white);
}

.imageRe {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 6rem;
}

@media (max-width: 1640px) {
    .imageRe {
        width: 400px;
    }
    .imageRe img {
        width: 380px;
    }
    .bodyTexts p {
        font-size: 1.6rem;
        line-height: 50px;
    }
    .footerBottom {
        gap: 3rem;
    }
    .footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 1440px) {
    .imageRe {
        width: 300px;
    }
    .imageRe img {
        width: 280px;
    }
    .container {
        width: 860px;
        padding: 3rem;
    }
    .headerTexts {
        width: 595px;
    }
    .headerTexts h1 {
        font-size: 4.5rem;
    }
    .headerTexts h2 {
        font-size: 3.0rem;
    }
    .bodyTexts p {
        font-size: 1.3rem;
        line-height: 40px;
    }
    .bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 60px;
        text-align: left;
    }
    .footerBottom {
        gap: 2rem;
    }
    .footerBottom .btn-redepharma {
        width: 20rem;
    }
    .footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 1111px) {
    .imageRe {
        display: none;
    }
    .imageRe img {
        display: none;
    }
    .container {
        width: 860px;
        padding: 3rem;
    }
    .headerTexts {
        width: 595px;
    }
    .headerTexts h1 {
        font-size: 4.5rem;
    }
    .headerTexts h2 {
        font-size: 3.0rem;
    }
    .bodyTexts p {
        font-size: 1.3rem;
        line-height: 40px;
    }
    .bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 60px;
        text-align: left;
    }
    .footerBottom {
        gap: 2rem;
    }
    .footerBottom .btn-redepharma {
        width: 20rem;
    }
    .footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 800px) {
    .container {
        width: 680px;
        padding: 1rem;
    }
    .headerTexts {
        width: 342px;
    }
    .headerTexts h1 {
        font-size: 2.575rem;
    }
    .headerTexts h2 {
        font-size: 2rem;
    }
    .bodyTexts p {
        font-size: 1.2rem;
        line-height: 40px;
    }
    .bodyTexts p strong {
        font-weight: 800;
        font-size: 1.4rem;
        line-height: 60px;
        text-align: left;
    }
    .footerBottom {
        gap: 1rem;
        flex-direction: column;
    }
    .footerBottom .btn-redepharma {
        width: 20rem;
        padding: 1rem;
    }
    .footerBottom .btn-redepharma span {
        font-size: 30px;
    }
}

@media (max-width: 450px) {
    .bodyTexts p {
        font-size: 1.3rem;
        line-height: 32px;
    }
    .bodyTexts p strong {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 32px;
        text-align: left;
    }
}