*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  width: 100%;
}

header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff8a15;
}

header .logo{
  position: relative;
  max-height: 80px;
}
header ul{
  position: relative;
  display: flex;
}
header ul li{
  list-style: none;
}
header ul li a{
  display: inline-block;
  color: #333;
  font-weight: 400;
  margin-left: 40px;
  text-decoration: none;
}