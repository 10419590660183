@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --redepharma-principal: #F0652B;
    --redepharma-secundaria: #8C1137;
    --redepharma-light: #F3F3F3;
    --redepharma-white: #FFFFFF;
    --redepharma-input-color: #D9D9D973;
    --redepharma-dark: #3C3C3C;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
*:focus {
    outline: none;
}

body {
    overflow: hidden;
}

a {
    text-decoration: none;
}

section.new--screen {
    background: linear-gradient(78.27deg, #F0652B 5.82%, #FF872D 95.68%);
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.new--container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 6rem;
}

.new--headerTexts {
    width: 250px;
}

.new--headerTexts h1 {
    color: var(--redepharma-white);
    font-weight: 800;
    font-size: 5.5rem;
    line-height: 95%;
    text-align: end;
}
.new--headerTexts h2 {
    color: var(--redepharma-secundaria);
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 100%;
    text-align: end;
}

.new--bodyTexts {
    display: flex;
    flex-direction: column;
}

.new--bodyTexts p {
    color: var(--redepharma-white);
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 40px;
    text-align: left;
}

.new--footerBottom {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: center;
}

.new--footerBottom .btn-redepharma {
    border: 3px solid var(--redepharma-secundaria);
    background-color: transparent;
    padding: 1.4rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s;
}

.new--footerBottom .btn-redepharma span {
    color: var(--redepharma-secundaria);
    font-weight: 400;
    font-size: 48px;
    transition: color .25s;
}

.new--footerBottom .btn-redepharma:hover {
    border: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-secundaria);
    cursor: pointer;
}

.new--footerBottom .btn-redepharma:hover span {
    color: var(--redepharma-white);
}

.new--imageRe {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.redepharma-textarea {
    resize: none;
    height: 200px;
}

.redepharma-input {
    padding: 12px 20px;
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    border-bottom: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-input-color);
    font-size: 30px;
    color: var(--redepharma-dark);
    -webkit-transition: .1s;
    transition: .1s;
}
.redepharma-select {
    padding: 12px 20px;
    width: 100%;
    margin-bottom: 30px;
    border: 0;
    border-bottom: 3px solid var(--redepharma-secundaria);
    background-color: var(--redepharma-input-color);
    font-size: 30px;
    color: var(--redepharma-dark);
    -webkit-transition: .1s;
    transition: .1s;
    -webkit-appearance: none;
  -moz-appearance: none;
    background-image:
    linear-gradient(45deg, transparent 50%,  var(--redepharma-secundaria) 50%),
    linear-gradient(135deg, var(--redepharma-secundaria) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position:
    calc(100% - 34px) calc(1em - 3px),
    calc(100% - 14px) calc(1em - 3px),
    100% 0;
  background-size:
    20px 20px,
    20px 20px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

.redepharma-input::placeholder {
    color: var(--redepharma-dark);
    font-size: 30px;
}

.redepharma-input:focus {
    border-bottom: 0;
    border: 3px solid var(--redepharma-secundaria);
}

.redepharma-input:disabled {
    background-color: var(--redepharma-secundaria);
    cursor: not-allowed;
}
.redepharma-input:disabled::placeholder {
    color: var(--redepharma-white);
}

.new--dados-numericos{
    display: flex;
    flex-direction: row;
    gap: 14px;
}



input[type=checkbox]
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    width: 15px;
    height: 15px;
    border: 1px solid black;
}


#label_identifica{
    padding-left: 10px;
    padding-right: 15px;
    color: var(--redepharma-white);
    font-weight: 200;
    font-size: 1.3rem;
    line-height: 60px;
    text-align: left;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (max-width: 1640px) {
    .new--imageRe {
        width: 400px;
    }
    .new--imageRe img {
        width: 380px;
    }
    .new--headerTexts {
        width: 195px;
    }
    .new--headerTexts h1 {
        font-size: 4.5rem;
    }
    .new--headerTexts h2 {
        font-size: 2.5rem;
    }
    .new--bodyTexts p {
        font-size: 1.5rem;
        line-height: 35px;
    }
    .new--footerForm {
        width: 850px;
    }
    .new--footerBottom {
        gap: 3rem;
    }
    .new--footerBottom .btn-redepharma span {
        font-size: 40px;
    }
    .new--container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 1rem 4rem;
    }
}

@media (max-width: 1440px) {
    .new--imageRe {
        width: 300px;
    }
    .new--imageRe img {
        width: 280px;
    }
    .new--container {
        padding: 1rem 2rem;
    }
    .new--bodyTexts p {
        font-size: 1.3rem;
        line-height: 30px;
    }
    .new--footerForm {
        width: 725px;
    }
    .new--footerBottom {
        gap: 1rem;
        justify-content: start;
    }
    .new--footerBottom .btn-redepharma {
        padding: 1.3rem;
        width: 20rem;
    }
}

@media (max-width: 1111px) {
    .new--imageRe {
        display: none;
    }
    .new--imageRe img {
        display: none;
    }
    .new--container {
        width: 860px;
        padding: 0rem;
        align-items: center;
    }
    .new--footerBottom {
        gap: 2rem;
        justify-content: center;
    }
    .footerBottom .btn-redepharma {
        width: 20rem;
    }
    .footerBottom .btn-redepharma span {
        font-size: 40px;
    }
}

@media (max-width: 800px) {
    .container {
        width: 680px;
        padding: 1rem;
    }
    .headerTexts {
        width: 342px;
    }
    .headerTexts h1 {
        font-size: 2.575rem;
    }
    .headerTexts h2 {
        font-size: 2rem;
    }
    .bodyTexts p {
        font-size: 1.2rem;
        line-height: 40px;
    }
    .bodyTexts p strong {
        font-weight: 800;
        font-size: 1.4rem;
        line-height: 60px;
        text-align: left;
    }
    .new--bodyTexts {
        padding: 0 4rem;
    }
    .new--footerForm {
        width: 425px;
    }
    #label_identifica {
        line-height: 30px;
    }
    div strong {
        line-height: 0px;
    }
    .new--footerBottom .btn-redepharma {
        width: 14rem;
        padding: 1rem;
    }
    .new--footerBottom .btn-redepharma span {
        font-size: 30px;
    }
}